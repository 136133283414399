import React from "react"
import remark from "remark"
import recommended from "remark-preset-lint-recommended"
import remarkHtml from "remark-html"
import "../styles/style.scss"
import { graphql, Link } from "gatsby"

export default function Service({
  image,
  frontmatter: { callToAction, description, details, id, order, slug },
}) {
  const toHTML = value =>
    remark().use(recommended).use(remarkHtml).processSync(value).toString()

  function createMarkup(data) {
    const dataHtml = toHTML(data)

    return { __html: dataHtml }
  }
  return (
    <section id={id}>
      <div className="container">
        <div className="hero-content">
          <div
            className="hero-text hero-service-text"
            data-sal={order % 2 === 0 ? "slide-right" : "slide-left"}
            data-sal-delay="300"
            data-sal-duration="1300"
            data-sal-easing="ease-out-back"
          >
            <h2>{description}</h2>
            <div dangerouslySetInnerHTML={createMarkup(details)}>{}</div>{" "}
            <div className="hero-button">
              <Link to={slug}>
                <button>{callToAction}</button>
              </Link>
            </div>
          </div>
          <div
            className="hero-image"
            data-sal={order % 2 === 0 ? "slide-left" : "slide-right"}
            data-sal-delay="300"
            data-sal-duration="1300"
            data-sal-easing="ease-out-back"
          >
            <img src={image} width="400" alt={description} />
          </div>
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment IndexServiceQuery on MarkdownRemark {
    frontmatter {
      callToAction
      description
      details
      id
      order
      slug
    }
  }
`

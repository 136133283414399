import React, { useEffect } from "react"
import { graphql } from "gatsby"
import scrollTo from "gatsby-plugin-smoothscroll"

import "../styles/style.scss"
import SEO from "../components/seo"
import Header from "../components/header"
import Footer from "../components/footer"
import Hero from "../components/hero"
import Service from "../components/service"

export default function IndexPage({ data }) {
  const services = data.allMarkdownRemark.edges
  const images = data.allFile.nodes.filter(node => {
    return node.extension === "svg"
  })
  let imageURL = ""

  useEffect(() => {
    scrollTo("#page-top")
  })

  return (
    <>
      <SEO title="Omnicode Solutions" />
      <Header page="Home" />
      <main id="page-top">
        <Hero
          title={data.markdownRemark.frontmatter.hero_heading}
          description={data.markdownRemark.frontmatter.hero_text}
        />

        {services.map(({ node }) => {
          images.forEach(image => {
            if (image.name === node.frontmatter.id) imageURL = image.publicURL
          })

          return <Service frontmatter={node.frontmatter} image={imageURL} />
        })}
      </main>
      <Footer id="contact" />
    </>
  )
}

export const query = graphql`
  query IndexPageQuery {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/content/services/" } }
      sort: { order: ASC, fields: frontmatter___order }
    ) {
      edges {
        node {
          ...IndexServiceQuery
        }
      }
    }
    markdownRemark(fileAbsolutePath: { regex: "/homepage.md/" }) {
      frontmatter {
        hero_heading
        hero_text
      }
    }
    allFile(filter: { relativePath: { regex: "images/" } }) {
      nodes {
        extension
        publicURL
        name
      }
    }
  }
`

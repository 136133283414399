import React from "react"
import remark from "remark"
import recommended from "remark-preset-lint-recommended"
import remarkHtml from "remark-html"
import "../styles/style.scss"
import omnicodeServices from "../images/omnicode-services.svg"

const Hero = props => {
  const toHTML = value =>
    remark().use(recommended).use(remarkHtml).processSync(value).toString()

  function createMarkup(data) {
    const dataHtml = toHTML(data)

    return { __html: dataHtml }
  }
  return (
    <section id={props.id} className="hero">
      <div className="container">
        <div className="hero-content">
          <div
            className="hero-text"
            data-sal="slide-down"
            data-sal-delay="600"
            data-sal-duration="1300"
            data-sal-easing="ease"
          >
            <h2>{props.title}</h2>
            <div dangerouslySetInnerHTML={createMarkup(props.description)}>
              {}
            </div>{" "}
          </div>
          <div
            className="hero-image"
            data-sal="slide-down"
            data-sal-delay="600"
            data-sal-duration="1300"
            data-sal-easing="ease"
          >
            <img src={omnicodeServices} alt="Omnicode Services" width="400" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
